

export default {
    name: 'UtilsMixins',
    data: () => {
        return {
            timeout: null,
            debounceMilliseconds: 250,
        }
    },
    methods: {
        debounceFunction(func) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                func()
            }, this.debounceMilliseconds)
        },
        getMonthTextFromDate(data) {
            const num = new Date(data).getMonth();
            var months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            const month = months[num];
            return month;
        },
    }
}