import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ResponseMixins",
  components: {
    ToastificationContent,
  },
  methods: {
    handleError(err) {
      if (err.status != 402) {
        if (err.response) {
          if (err.response.data.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.error,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
        } else {
          if(err?.name === 'NavigationDuplicated'){
            return 
          }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops Something went wrong, Please retry.",
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          // }
        }
      }
    },
    handleResponse(res, substituteMessage) {
      if (res.data.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: substituteMessage,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },

    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: this.getToastIconFromVariant(variant),
          variant: variant,
        },
      });
    },

    getToastIconFromVariant(variant) {
      if (variant == "success") {
        return "CheckIcon";
      } else if (variant == "danger") {
        return "AlertOctagonIcon";
      } else if (variant == "warning") {
        return "AlertTriangleIcon";
      } else if (variant == "info") {
        return "AlertCircleIcon";
      } else if (variant == "primary") {
        return "CircleIcon";
      } else if (variant == "secondary") {
        return "GridIcon";
      }
    },
  },
};
